import NavBar from "@/components/NavBar";

export default {
  name: "PublicLayout",
  components: {
    NavBar
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
