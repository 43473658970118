import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "../src/assets/scss/index.scss"; // UI
import {businessApi} from "@/api/business";

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

import router from "@/router";
import store from "@/store";
Vue.prototype.$businessApi = businessApi;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
